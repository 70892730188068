<template>
  <div class="common-footer">
    <div class="footer-top">
      <div class="footer-top-company">
        <img :src="$http + '/img/logoHeader.png'" alt="">
        <span>北京珑巳飞扬科技有限公司</span>
      </div>
      <div class="footer-top-list" v-for="(item, index) in list" :key="index">
        <div class="footer-top-list-name">{{item.name}}</div>
        <div class="footer-top-list-info" v-for="(val, _index) in item.children" @click="toRouterIn(item)" :key="_index">
          <span>{{val.name}}</span>
        </div>
      </div>
      <div class="footer-top-scan">
        <!-- <img src="https://upload.wikimedia.org/wikipedia/commons/f/f2/%E7%BB%B4%E5%9F%BA%E4%B8%AD%E6%96%87%E7%A4%BE%E5%8A%A0%E7%BE%A4%E4%BA%8C%E7%BB%B4%E7%A0%81.png" alt=""> -->
      </div>
    </div>
    <div class="footer-under">
      <div class="footer-under-info">
        <span>@2024 Longsqin. All rights reserved.</span>
        <span @click="toIcp">北京珑巳飞扬科技有限公司 京ICP备2024043714号-2</span>
        <span @click="toBeiAn">
          <img :src="$http + '/img/gongan.png'" alt="">
          <span>京公网安备11010802043901</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      list: [
        {
          name: '首页',
          children: [
            {
              name: '产品简介'
            },
            {
              name: '创作历程'
            },
            {
              name: '推荐试听'
            }
          ]
        },
        {
          name: '产品',
          children: [
            {
              name: '产品详情'
            },
            {
              name: '产品特点'
            },
            {
              name: '产品优势'
            }
          ]
        },
        {
          name: '关于',
          children: [
            {
              name: '创始人'
            },
            {
              name: '发展历程'
            },
            {
              name: '最新动态'
            }
          ]
        }
        ,
        {
          name: '下载',
          children: []
        }
        ,
        {
          name: '帮助',
          children: []
        }
      ]
    }
  },
  methods: {
    toIcp(){
      window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank')
    },
    toBeiAn(){
      window.open('https://beian.mps.gov.cn/#/query/webSearch?code=11010802043901', '_blank')
    },
    toRouterIn(item){
      let path = ''
      switch (item.name) {
        case '首页':
          path = '/qy/zh/home'
        break;
        case '产品':
          path = '/qy/zh/detail'
        break;
        case '关于':
          path = '/qy/zh/about'
        break;
      }
      if(path!=this.$route.path){
        this.$router.push({
          path: path
        })
      }
    }
  }
}
</script>

<style scoped lang='scss'>
    .common-footer{
      border-top: 1px solid #d39a4f;
      width: 100%;
      background: #000;
      color: #fff;
      display: flex;
      flex-direction: column;
      .footer-top{
        padding: 16px 250px 0 252px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        .footer-top-company{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-right: 166px;
          img{
            width: 138px;
            height: 57px;
            margin-bottom: 18px;
          }
          span{
            font-size: 24px;
            color: rgba(255,255,255,.6);
            line-height: 29px;
            font-family: Source-KeynoteartHans;
          }
        }
        .footer-top-list{
          text-align: left;
          width: 64px;
          .footer-top-list-name{
            cursor: pointer;
            font-weight: 600;
            margin-bottom: 16px;
            font-size: 20px;
            line-height: 33px;
          }
          .footer-top-list-info{
            color: rgba(255,255,255,.6);
            font-size: 16px;
            line-height: 25px;
            margin-bottom: 12px;
            cursor: pointer;
          }
          .footer-top-list-info:hover{
            color: rgba(255,255,255,1);
          }
        }
        .footer-top-scan{
          width: 120px;
          height: 120px;
          background: linear-gradient(180deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0.4) 49%, rgba(255,255,255,0.5) 100%, rgba(0,0,0,0) 100%);
          border-radius: 8px;
          opacity: 0.6;
          backdrop-filter: blur(10px);
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            width: 105px;
            height: 105px;
            border-radius: 8px;
          }
        }
        
      }
      .footer-under{
        margin-top: 26px;
        padding: 0 0 10px 0;
        font-size: 16px;
        line-height: 25px;
        color: rgba(255,255,255,.6);
        .footer-under-info{
          display: flex;
          align-items: center;
          justify-content: center;
          span{
            &:nth-of-type(2){
              cursor: pointer;
              margin: 0 58px;
            }
            &:nth-of-type(3){
              cursor: pointer;
              display: flex;
              align-items: center;
              img{
                width: 20px;
                height: 20px;
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
</style>

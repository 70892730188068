<template>
  <div class="about-page">
    <common-header></common-header>
    <div class="about-content">
      <!-- banner图片 -->
      <div class="about-banner" :style="{'background':'url(' + $http + '/zh/about/banner.jpg)'}">
        <img class="banner-font" :src="$http + '/zh/about/bannerFont.png'" alt="图片文字" />
      </div>
      <!-- 团队介绍 -->
      <div class="about-team">
        <img class="about-title-img" :src="$http + '/zh/about/team.png'" />
        <div class="about-introduce">
          <div class="about-info" v-for="item in teamList" :key="item.nameUrl">
            <img class="user-img" :src="item.nameUrl" />
            <img class="user-type" :src="item.typeUrl" />
            <span class="user-name">{{item.name}}</span>
            <img class="user-back" :src="$http + '/zh/about/teamBack.png'" />
          </div>
        </div>
      </div>
      <!-- 合作内容 -->
      <div class="team-box" :style="{'background':'url(' + $http + '/zh/about/teamFont.png), black'}">
        <div class="team-text">
          <div>陈沁扬，中国内地知名音乐制作人，编曲人，北京现代音乐研修学院音乐技术系编曲专业大师班教师，网易云音乐“石头计划”第一季、第二季音乐制作人，中国音乐家协会流行音乐协会会员，Jamstik吉他合作艺术家，2019、2020、2021、2022年连续四届担任《中国好声音》总决赛音乐制作人专业评审团。 </div>
          <div>代表作品：成龙《壮志在我胸2020》、张杰《心光》、李宇春《咏春》、萧敬腾/张淇《武家坡2021》、袁娅维《为你而来》、张碧晨《听雪》等。 </div>
          <div>合作艺人：成龙、黄渤、王一博、李宇春、张杰、张碧晨、腾格尔、袁娅维、郁可唯等。</div>
        </div>
      </div>
      <!-- 发展历程 -->
      <div class="about-development" :style="{'background':'url(' + $http + '/zh/about/developmentBack.png), black'}">
        <img class="about-title-img" :src="$http + '/zh/about/development.png'" />
        <div class="development-introduce">
          <div class="introduce-info">
            <img class="introduce-year" style="margin-bottom:24px" :src="$http + '/zh/about/2021.png'" />
            <span class="introduce-name" style="margin-bottom: 24px">开始立项。</span>
            <img class="introduce-img1" :src="$http + '/zh/about/2021Pic.png'" />
          </div>
          <div class="introduce-info">
            <img class="introduce-img2" :src="$http + '/zh/about/2022Pic.png'" />
            <img class="introduce-year" :src="$http + '/zh/about/2022.png'" style="margin-top:24px" />
            <span class="introduce-name" style="margin-top:24px">开始采样。</span>
          </div>
          <div class="introduce-info">
            <img class="introduce-cd" :src="$http + '/zh/about/2023CD.png'" />
            <img class="introduce-special-img" :src="$http + '/zh/about/2023Pic.jpg'" />
            <img class="introduce-year" :src="$http + '/zh/about/2023.png'"  style="margin-top:24px" />
            <span class="introduce-name"  style="margin-top:24px">编辑、处理采样，全球首发。</span>
          </div>
        </div>
      </div>
      <!-- 最新动态 -->
      <div class="about-news" :style="{'background':'url(' + $http + '/zh/about/newsBack.png), black'}">
        <img class="about-title-img" :src="$http + '/zh/about/news.png'" />
        <img class="about-title" :src="$http + '/zh/about/newsFont.png'" />
        <div class="news-banner">
          <img class="new-banner" :src="$http + '/zh/about/newsBanner.png'" />
          <div class="news-font">
            <div class="new-font">
              <div>Qin Strings 全球首发发布会，将于2024年1月18日，在叮咚音频召开，届时行业内一线的音乐大咖、各个音乐院校的一线老师、Qin Strings的幕后制作团队等等都将出席这次的发布会。</div>
              <div>发布会当天陈沁扬老师也会为大家详细介绍这款软件的设计、使用，以及更多的制作过程中有趣的事。</div>
              <div>同时发布会当天到场的不管老师还是同学们，都能以一个特别的价格，购买到这款国产弦乐音源。</div>
              <div>扫描下方二维码，打开叮咚音频小程序，点击近期课程/沙龙即可预约参与本次发布会。</div>
            </div>
            <img class="news-code" :src="$http + '/zh/about/newsCode.png'" />
          </div>
        </div>
      </div>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import commonFooter from "../../components/zh/commonFooter.vue";
import commonHeader from "../../components/zh/commonHeader.vue";
export default {
  components: {
    commonHeader,
    commonFooter,
  },
  data(){
    return{
      // 团队信息列表
      teamList: [
        {
          name: '陈沁扬',
          nameUrl: this.$http + '/zh/about/teamChen.png',
          typeUrl: this.$http + '/zh/about/creater.png'
        },
        {
          name: '金田录音棚  王小四',
          nameUrl: this.$http + '/zh/about/teamWang.png',
          typeUrl: this.$http + '/zh/about/recordingEngineer.png'
        },
        {
          name: '国际首席爱乐乐团',
          nameUrl: this.$http + '/zh/about/teamMusic.png',
          typeUrl: this.$http + '/zh/about/stringOrchestra.png'
        }
      ]
    }
  }
};
</script>

<style scoped lang='scss'>
.about-page {
  width: 100%;
  height: 100%;
  .about-content{
    width: 100%;
    height: 100%;
    // 顶部banner
    .about-banner {
      padding-top: 90px;
      width: 100%;
      height: 814px;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      background-repeat: no-repeat !important;
      background-size: 100% 100% !important;
      
      .banner-font {
        width: 873px;
        height: 248px;
        margin-top: 235px;
      }
    }
    // 团队介绍
    .about-team{
      width: 100%;
      background: #000000;
      // padding-bottom: 97px;
      .about-title-img{
        margin: 80px 0 86px 270px;
        width: 678px;
        height: 153px;
      }
      .about-introduce{
        margin-left: 88px;
        padding: 0 255px 0 168px;
        display: flex;
        justify-content: space-between;
        .about-info{
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          .user-img{
            width: 391px;
            height: 544px;
          }
          .user-type{
            height: 66px;
            margin: 42px 0 19px 0;
          }
          .user-name{
            font-size: 28px;
            font-family: PingFang SC;
            color: #fff;
            margin-bottom: 70px;
          }
          .user-back{
            position: absolute;
            left: -90px;
            bottom: 123px;
            width: 293px;
            height: 163px;
          }
        }
      }
    }
    // 合作内容
    .team-box{
      width: 100%;
      padding: 85px 0 97px 0;
      background-repeat: no-repeat !important;
      .team-text{
        width: 1318px;
        height: 324px;
        color: #fff;
        font-size: 24px;
        margin: 0px 0 0 301px;
        font-family: 'PingFang SC';
        div{
          line-height: 54px;
          text-indent: 2em;
        }
      }
    }
    // 发展历程
    .about-development{
      width: 100%;
      padding-bottom: 32px;
      background-repeat: no-repeat !important;
      .about-title-img{
        margin: 80px 0 86px 270px;
        width: 818px;
        height: 153px;
      }
      .development-introduce{
        margin: 22px 210px 0px 270px;
        display: flex;
        justify-content: space-between;
        .introduce-info{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .introduce-name{
            font-size: 28px;
            font-family: PingFang SC;
            color: #FFFFFF;
          }
          .introduce-year{
            width: 145px; 
            height: 47px;
          }
          .introduce-cd{
            width: 483px;
            height: 269px;
            transform: translate(47px, 50px);
          }
          .introduce-img1{
            width: 366px;
            height: 564px;
          }
          .introduce-img2{
            width: 366px;
            height: 504px;
          }
          .introduce-special-img{
            width: 582px;
            height: 328px;
          }
        }
      }
    }
    // 最新动态
    .about-news{
      width: 100%;
      background-repeat: no-repeat !important;
      padding: 0 252px 86px 264px;
      box-sizing: border-box;
      .about-title-img{
        margin: 84px 0 79px 0px;
        width: 558px;
        height: 152px;
      }
      .about-title{
        display: block; 
        margin-left: 283px;
        width: 827px;
        height: 85px;
      }
      .news-banner{
        display: flex;
        justify-content: space-between;
        margin-top: 78px;
        .new-banner{
          width: 472px;
          height: 774px;
        }
        .news-font{
          margin-top: 24px;
          width: 810px;
          height: 486px;
          .new-font{
            line-height: 54px;
            color: #fff;
            font-size: 24px;
            text-indent: 2em;
            // font-family: 'PingFang SC';
          }
          .news-code{
            margin-top: 100px;
            float: right;
            width: 180px;
            height: 181px;
          }
        }
      }
    }
  }
}
</style>